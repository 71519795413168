import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>KEEPING A DENTURE OR BRIDGE CLEAN</h2>
      <h3>Why is cleaning important?</h3>
      <p>Just like natural teeth, denture and bridges collect bits of food, plaque (a sticky deposit, mostly made up of bacteria) and tartar (hardened plaque). By keeping them clean, you can stop decay in any remaining natural teeth and help prevent gum disease (which can lead to tooth-loss). It's also good for your comfort and appearance to clean dentures, as dirty dentures can make your mouth swollen and sore.</p>
      <p>Cleaning a denture is quite easy because, of course, you can take it out of your mouth. Cleaning all round and underneath a fixed bridge is harder. Your dentist or hygienist will show you how to do it.</p>
      <h3>How do I look after a denture?</h3>
      <p>Clean the denture over a basin of water so that it does not break if you drop it.</p>
      <p>Brush the denture inside and out every day. Use a soft-to-medium brush so that you do not scratch the denture. Use your normal toothpaste, or soap and water. Then rinse the denture.</p>
      <p>Ask your dentist about denture-soaking solutions.  Soaking a denture will not clean it. You also need to brush it. Rinse the denture before you put it back in your mouth.</p>
      <p>Ideally, dentures should be left out of the mouth for at least four to six hours, preferably eight hours, in every twenty-four.  However, if you find this impractical or embarrassing, you should still make sure you clean your dentures by brushing with toothbrush and toothpaste.</p>
      <p>Keep a denture dry or in a denture-soaking solution when you are not wearing it. These solutions are mild disinfectants available at all chemists' shops.</p>

      <h3>How do I clean a bridge?</h3>
      <p>How you clean will depend on where the bridge is in the mouth and its design. As well as normal brushing with a fluoride toothpaste  and flossing at each end of the bridge, you need to keep the bridge clean underneath.</p>
      <p>You might use:</p>
      <ul>
        <li>floss with a threader or a special floss with a stiff end which you can poke underneath the bridge and pull through
          "bottle brushes" (your dentist will tell you what size and shape to buy)</li>
        <li>water jets - sending a stream of water underneath the bridge.</li>
      </ul>
      <h3>What are the benefits?</h3>
      <p>By keeping a denture or bridge clean, you help keep your gums and teeth healthy and avoid bad breath.</p>
      <p>People who wear dentures sometimes get an infection called oral thrush. Thrush is more likely if you wear the denture all the time.  Good denture  hygiene is important for preventing oral thrush.</p>
    </LightboxLayout>
  )
}

export default Page